import React from "react"

import styles from "./inputCheckbox.module.scss"

const InputCheckbox = props => {
  return (
    <label className={styles.checkbox} htmlFor={props.id}>
      <input
        className="screen-reader-only"
        type="checkbox"
        name={props.name}
        id={props.id}
        // defaultChecked="checked"
        defaultChecked={props.defaultChecked}
        value={props.value}
        onChange={event => {
          if (props.onChange != null) {
            props.onChange(event)
          }
        }}
      />
      <span>{props.label}</span>
    </label>
  )
}

export default InputCheckbox
